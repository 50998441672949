import React, { useState, useEffect } from 'react';
import { SellerNav } from './SellerNav';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import ReactApexChart from 'react-apexcharts'; // Import ApexCharts wrapper
export const CarEstimated = () => {
    const navigate = useNavigate();
    const [location, setLocation] = useState('');
    const [vin, setVin] = useState('');
    const [year, setYear] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [mileage, setMileage] = useState('');
    const [exteriorimage, setExteriorImage] = useState('');
    const [exteriorimagetwo, setExteriorImageTwo] = useState('');
    const [exteriorimagethree, setExteriorImageThree] = useState('');
    const [exteriorimagefour, setExteriorImageFour] = useState('');
    const [sessionVehicleId, setSessionVehicleId] = useState('');
    const [getPrice, setGetPrice] = useState('');
    const [averageprice, setGetaveragePrice] = useState('');
    const [belowprice, setGetbelowPrice] = useState('');
    const [firmPrice, setFirmPrice] = useState('');
    const storedVehicleId = sessionStorage.getItem('Vehicle_Id');
    const [fadingpaints, setFadingPaints] = useState('');
    const [rust, setRust] = useState('');
    const [minordamage, setMinorDamage] = useState('');
    const [dents, setDents] = useState('');
    const [haildamage, setHailDamage] = useState('');
    const [ripsOrTears, setRipsOrTears] = useState(false); 
    const [visibleStain, setVisibleStain] = useState(false);
    const [strongSmell, setStrongSmell] = useState(false);
    const [damagedSystems, setDamagedSystems] = useState(false);
    const [hasOriginalRims, setHasOriginalRims] = useState(null);
    const [tireReplacement, setTireReplacement] = useState(null); 
    const [crackOnWindshield, setCrackOnWindshield] = useState(null);
    const [deliveryOption, setDeliveryOption] = useState('');  // 'Pickup' or 'Dropoff'
    const [pickupAddress, setPickupAddress] = useState('');    // Address for Pickup option
    const carChaserAddress = "801- 1275 Finch Ave W, North York ON M3L 0L5";   // Predefined Car Chaser address
    const [expectedDate, setExpectedDate] = useState('');  // State for storing expected date
    const [biddingDate, setBiddingDate] = useState('');
    const [biddingHour, setBiddingHour] = useState('');
    const [biddingMinute, setBiddingMinute] = useState('00');
    const [biddingPeriod, setBiddingPeriod] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const today = new Date().toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });
const currentHourToronto = new Date().toLocaleString('en-CA', { hour: '2-digit', timeZone: 'America/Toronto', hour12: false });

console.log('Current Date in Toronto:', today);
console.log('Current Hour in Toronto:', currentHourToronto);
  const [isHovered, setIsHovered] = useState(false);
    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        setBiddingDate(selectedDate);

        if (selectedDate === today) {
            const currentHour = new Date().getHours();
            const currentMinute = new Date().getMinutes();
            
            if (currentHour >= 9 && currentHour < 18) {
                // Set the available hours to the current hour and later for today
                setBiddingHour(currentHour.toString().padStart(2, '0'));
                setBiddingPeriod(currentHour >= 12 ? 'PM' : 'AM');
                setErrorMessage('');  // Clear the error message
            } else if (currentHour >= 18) {
                // If it's past 6 PM, show an error message
                setErrorMessage('This time slot is not available today. Please select a future date.');
                setBiddingHour('');  // Clear the hour
                setBiddingPeriod('');
            } else {
                // If it's before 9 AM, set 9 AM as the default time
                setBiddingHour('09');
                setBiddingMinute('00');
                setBiddingPeriod('AM');
                setErrorMessage('');
            }
        } else {
            // For future dates, reset the time to 9 AM
            setBiddingHour('09');
            setBiddingMinute('00');
            setBiddingPeriod('AM');
            setErrorMessage('');
        }
    };


    const handleDeliveryOptionChange = (e) => {
      setDeliveryOption(e.target.value);
      if (e.target.value === 'Dropoff') {
        setPickupAddress(carChaserAddress);  // Automatically set to Car Chaser address if Dropoff is selected
      } else {
        setPickupAddress('');  // Reset address if Pickup is selected
      }
    };
    useEffect(() => {
    if (storedVehicleId) {
         fetchData();
    } else {
         console.error('Vehicle Id is null');
    }
    }, [storedVehicleId]);

    const fetchData = async () => {
        try {
            const response = await api.get(`/seller/vehicle/${storedVehicleId}`);
            const vehicleData = response.data.vehicle;
            setRust(vehicleData.Rust || '')
            setFadingPaints(vehicleData.Fading_Paints || '');
            setLocation(vehicleData.Location || '');
            setFirmPrice(vehicleData.firm_price || '');
            setVin(vehicleData.VIN || '');
            const vin = vehicleData.VIN;
            setYear(vehicleData.Year || '');
            const year = vehicleData.Year;
            setModel(vehicleData.Model || '');
            const model = vehicleData.Model;
            setTrim(vehicleData.Trim || '');
            const trim = vehicleData.Trim;
            setMileage(vehicleData.Mileage || '');
            const mileage = vehicleData.Mileage;
            setMake(vehicleData.Make || '');
            const make = vehicleData.Make;
            if (vehicleData.Rust === 1) {
                 setRust('Rust');
            }else {
                 setRust('');
            }
            if (vehicleData.Fading_Paints === 1) {
                setFadingPaints('Fading Paints');
            }else{
                 setFadingPaints('');
            }
            if (vehicleData.Hail_Damage === 1) {
                  setHailDamage('Hail Damage');
            }else{
                 setHailDamage(''); 
            }
            if (vehicleData.Dents === 1) {
                 setDents('Dents');
            }else{
                 setDents('');
            }
            if (vehicleData.Minor_Damage === 1) {
                setMinorDamage('Minor Damage');
            }else{
                setMinorDamage('');
            }
            if (vehicleData.Nav_Entmt_Ctrl_Dmg_Sys === 1) {
                setDamagedSystems('Damaged Systems');
            }else{
                setDamagedSystems('');
            }
            if (vehicleData.Strong_Smell === 1) {
                setStrongSmell('Strong Smell');
            }else{
                setStrongSmell('');
            }
            if (vehicleData.Seats_Visible_Stain === 1) {
                setVisibleStain('Visible Stain On The Seats');
            }else{
                setVisibleStain('');
            }
            if (vehicleData.Seats_Rips_Tears === 1) {
                setRipsOrTears('Rips Or Tears On The Seats');
            }else{
                setRipsOrTears('');
            }
            if (vehicleData.Original_Factory_Rims === 1) {
                setHasOriginalRims('Yes');
            }else{
                setHasOriginalRims('');
            }
            if (vehicleData.Tires_Repld_12_Months === 1) {
                setTireReplacement('Yes');
            }else{
                setTireReplacement('');
            }
            if (vehicleData.Windshield_Crack === 1) {
                setCrackOnWindshield('Yes');
            }else{
                setCrackOnWindshield('');
            }
            setExteriorImage(vehicleData['Exterior_Image'] || '');
            setExteriorImageTwo(vehicleData['Exterior_Image2'] || '');
            setExteriorImageThree(vehicleData['Exterior_Image3'] || '');
            setExteriorImageFour(vehicleData['Exterior_Image4'] || '');
            try {
                const response = await api.get(`/seller/vehicleofferprice/${vin}/${mileage}/${trim}/${year}/${make}/${model}`);
                const offerprice = response.data.third_price;
                const average = response.data.second_price;
                const belowprice = response.data.first_price;
            
                // Create an array and sort it
                const prices = [offerprice, average, belowprice].sort((a, b) => a - b);
                console.log(prices)
            
                // Assign sorted values to respective price categories
                setGetbelowPrice(prices[0]); // Lowest price
                setGetaveragePrice(prices[1]); // Middle price
                setGetPrice(prices[2]); // Highest price
            
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching offer price:', error);
            }

        } catch (error) {
            console.error('Error fetching vehicle details:', error);
        }
    };
    
    // Deduction of price by Checks
    const rustValue = rust === 'Rust' ? 800 : 0;
    const fadingPaintsValue = fadingpaints === 'Fading Paints' ? 750 : 0;
    const haildamgeValue = haildamage === 'Hail Damage' ? 800 : 0;
    const dentsValue = dents === 'Dents' ? 700 : 0;
    const minordamageValue = minordamage === 'Minor Damage' ? 300 : 0;
    const ripsOrTearsvalue  = ripsOrTears === 'Rips Or Tears On The Seats' ? 750 : 0;
    const visibleStainvalue = visibleStain === 'Visible Stain On The Seats' ? 350 : 0;
    const strongSmellvalue = strongSmell === 'Strong Smell' ? 350 : 0;
    const damagedSystemsvalue = damagedSystems === 'Damaged Systems' ? 1500 : 0;
    const hasOriginalRimsvalue = hasOriginalRims === 'Yes' ? 1000 : 0;
    const tireReplacementvalue = tireReplacement === 'Yes' ? 1000 : 0;
    const crackOnWindshieldvalue = crackOnWindshield === 'Yes' ? 800 : 0;
    const totalDeductions = rustValue + fadingPaintsValue + haildamgeValue + dentsValue + minordamageValue + ripsOrTearsvalue + visibleStainvalue + strongSmellvalue + damagedSystemsvalue + hasOriginalRimsvalue + tireReplacementvalue + crackOnWindshieldvalue;
    const finalPrice = averageprice - totalDeductions;
    const [floorPrice, setFloorPrice] = useState('');
    const handlePriceChange = (value) => {
        // Remove any non-numeric characters (except for the decimal point)
        const numericValue = value.replace(/[^0-9.]/g, '');
        setFloorPrice(numericValue);  // Store only the numeric value
    };
    const postDataFirmPrice = async () => {
        try {
            let hour = parseInt(biddingHour);
            
            // Adjust for AM/PM
            if (biddingPeriod === "PM" && hour !== 12) {
                hour += 12;
            } else if (biddingPeriod === "AM" && hour === 12) {
                hour = 0;
            }
    
            // Ensure bidding time is between 9 AM and 6 PM (Toronto time)
            if (hour < 9 || hour >= 18) {
                setErrorMessage('Time slot is not available. Kindly select a future date.');
                return;
            }
    
            const formattedTime = `${hour.toString().padStart(2, '0')}:${biddingMinute.padStart(2, '0')}:00`;
            const fullBiddingDateTime = `${biddingDate} ${formattedTime}`;
            
            const data = {
                reserve_price: floorPrice,
                bidding_date: fullBiddingDateTime,
                delivery_option: deliveryOption,
                pickup_address: deliveryOption === 'Pickup' ? pickupAddress : carChaserAddress,
                expected_date: expectedDate,
            };
    
            // Make the API request
            const response = await api.post(`/seller/updatefirmprice/${storedVehicleId}`, data);
    
            // Navigate to the booking appointment page
            navigate(`/thankyou`);
        } catch (error) {
            console.error('Error updating firm price:', error);
        }
    };

    const formatWithCommas = (value) => {
    // Ensure the value is a number and format it with commas and two decimal places
        if (isNaN(value) || value === null || value === undefined) {
            return '$0.00';
        }
        return `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        };
    // Chart options and data
        const chartOptions = {
            chart: {
                type: 'area',
                height: 250
            },
            series: [{
                name: 'Price',
                data: [belowprice, averageprice, getPrice]
            }],
            xaxis: {
                categories: ['Below Market', 'Average Market', 'Above Market']
            },
            grid: {
                show: false
            },
                tooltip: {
                y: {
                formatter: (value) => formatWithCommas(value)
                }
            }
        };
return (
<section className="car-details">
   <SellerNav />
   <div class="average-newlayout my-5">
    <div className="container">
    <div class="info-image">
        <div class="info-image-inner">
        {(exteriorimage || exteriorimagetwo || exteriorimagethree || exteriorimagefour) && (
               <img
               src={`https://backend.carchaser.ca/uploads/${storedVehicleId}/${exteriorimage || exteriorimagetwo || exteriorimagethree || exteriorimagefour}`}
               alt="Exterior"
               />
               )}
            </div>
         </div>
         <h2 class="car-esti-heading mt-5">{year} {make}</h2>
         <p class="fw-bold"><strong>{model}</strong> | {trim}</p>
         <div class="estimate-heading-infonew">
            <h5>Car Chaser's Estimated Value Range for Your Car.</h5>
            <p>Our estimated range provides a preliminary evaluation by leveraging competition within our dealer network. This estimate serves as a guide for determining your car's price.</p>
         </div>
      {
        /*
      <div id="mountainChart">
         <ReactApexChart options={chartOptions} series={chartOptions.series} type="area" height={350} />
      </div>
      */
      }
  <div className="estimate-average-new pt-3">
    <div className="row">
        <div className="col-lg-4 col-4 fw-bold">
            <div className="circle-grey mb-2">
                <span></span>
            </div>
            <p>low</p>
            <p>${belowprice ? belowprice.toLocaleString() : '0'}</p>
        </div>
        <div className="col-lg-4 col-4 fw-bold text-center">
            <div className="circle-grey mb-2 ml-4">
                <span class="second-disc"></span>
            </div>
            <p>average</p>
            <p>${averageprice ? averageprice.toLocaleString() : '0'}</p>
        </div>
        <div className="col-lg-4 col-4 fw-bold text-right">
            <div className="circle-grey mb-2 ml-4">
                <span class="third-disc"></span>
            </div>
            <p>awesome</p>
            <p>${getPrice ? getPrice.toLocaleString() : '0'}</p>  {/* Show highest price */}
        </div>
    </div>
</div>

   <div class="reserve-price-new mt-4">
       <div class="reserve-combo">
        <h5>Reserve price</h5>
        <div
        onMouseEnter={() => setIsHovered(true)}  // Show help text on hover
        onMouseLeave={() => setIsHovered(false)} // Hide help text when not hovering
        style={{ display: 'inline-block', marginLeft: '10px'}} // Ensure correct positioning
      >
        <img src="../images/help.png" alt="help" style={{ cursor: 'pointer' }} />
        
        {/* Conditionally render the help text */}
        {isHovered && (
          <span className="help-text" style={{
            position: 'absolute',
            top: '4%',  // Position below the image
            left: '103%',
            transform: 'translateX(-50%)',
            backgroundColor: '#f9f9f9',
            border: '1px solid #ddd',
            padding: '3px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            color: '#333',
            width: '385px',            
            zIndex: 1
          }}>
            The fastest way to sell your car is by choosing a realistic reserve price
          </span>
        )}
      </div>
      </div>
        <input
        type="text"  // Use 'text' to allow formatting with $ and commas
        className="form-control"
        value={floorPrice ? `$${parseFloat(floorPrice).toLocaleString()}` : ''}  // Format with $ and commas
        onChange={(e) => handlePriceChange(e.target.value)}  // Handle change with custom function
    />
     </div>
   
     <div className="average-newbidding mt-4">
    <div className="row">
        <div className="col-lg-6 col-6">
            <div className="form-check mb-2">
                <label>
                    <input 
                        type="radio" 
                        className="form-check-input"  // Proper className for React
                        value="Pickup" 
                        checked={deliveryOption === 'Pickup'} 
                        onChange={handleDeliveryOptionChange}
                    />
                    Pickup
                </label>
            </div>
        </div>
        <div className="col-lg-6 col-6">
            <div className="form-check mb-2">
                <label>
                    <input 
                        type="radio" 
                        className="form-check-input"  // Proper className for React
                        value="Dropoff" 
                        checked={deliveryOption === 'Dropoff'} 
                        onChange={handleDeliveryOptionChange}
                    />
                    Dropoff
                </label>
            </div>
        </div>
    </div>
    <div className="row">
        {deliveryOption === 'Dropoff' && (
            <div className="col-lg-9 col-12">
                <div className="form-average">
                     <h5>Drop-off is free, Monday to Friday, 9 AM - 5 PM.</h5>
                    <textarea 
                        className="form-control"  // Use form-control for better layout
                        rows="2"  // Ensure enough rows for better visibility
                        value={carChaserAddress} 
                        disabled 
                    />
                </div>
            </div>
        )} 
        {deliveryOption === 'Pickup' && (
            <div className="col-lg-9 col-12">
                <div className="form-average">
                    <h5>Pickup within the GTA is $165</h5>
                    <textarea 
                        className="form-control"  // Use form-control for better layout
                        rows="4"  // Ensure enough rows for better visibility
                        value={pickupAddress} 
                        onChange={(e) => setPickupAddress(e.target.value)} 
                        placeholder="Enter address for pickup"
                    />
                </div>
            </div>
        )}
    </div>
    <div className="average-newbidding mt-4">
        <div className="row">
        <div className="col-lg-6 col-6">
    <div className="average-time">
        <h5>Live Auction Date and Time</h5>
        <div className="average-timeinner">
            <input 
                type="date" 
                className="form-control"
                value={biddingDate} 
                onChange={handleDateChange}
                min={today}  // Disable past dates
            />
            <div className="bidding-time">
                {biddingDate === today && currentHourToronto >= 18 && (
                    <p className="error-message text-alert">
                        Please select another date, today's time is not available.
                    </p>
                )}
                {biddingDate === today && currentHourToronto < 9 && (
                    <p className="error-message text-alert">
                        Please select a time between 9 AM and 6 PM.
                    </p>
                )}
            {biddingDate === today && currentHourToronto < 18 && (
    <select 
        value={biddingHour}
        onChange={(e) => setBiddingHour(e.target.value)}
    >
        {/* Calculate future time slots starting from the next hour */}
        {Array.from({ length: Math.max(0, 18 - (currentHourToronto + 1)) }, (_, i) => {
            const hour = currentHourToronto + 1 + i; // Start from next hour
            if (hour >= 9 && hour < 18) { // Ensure hours remain between 9 AM and 6 PM
                return (
                    <option key={hour} value={hour.toString().padStart(2, '0')}>
                        {hour % 12 === 0 ? 12 : hour % 12} {hour >= 12 ? 'PM' : 'AM'}
                    </option>
                );
            }
            return null;
        })}
    </select>
)}

             {/* For future dates, show full range from 9 AM to 6 PM */}
{biddingDate !== today && (
    <select 
        value={biddingHour}
        onChange={(e) => setBiddingHour(e.target.value)}
    >
        <option value="09">09 AM</option>
        <option value="10">10 AM</option>
        <option value="11">11 AM</option>
        <option value="12">12 PM</option>
        <option value="13">01 PM</option>
        <option value="14">02 PM</option>
        <option value="15">03 PM</option>
        <option value="16">04 PM</option>
        <option value="17">05 PM</option>
        <option value="18">06 PM</option>
    </select>
)}
            </div>
        </div>

        {/* Show error message if there is an error */}
        {errorMessage && <p className="error-message text-alert">{errorMessage}</p>}
        
    </div>
</div>
   
            <div className="col-lg-6 col-6">
                <div className="average-time">
                    <h5>Pick up or drop off date</h5>
                    <input 
                        type="date" 
                        className="form-control"
                        value={expectedDate} 
                        onChange={(e) => setExpectedDate(e.target.value)}  
                        min={today}  // Disable past dates
                    />
                </div>
            </div>
        </div>
    </div>
</div>

            <div className="estimate-button pb-5">
                <button className="btn btn-primary" onClick={postDataFirmPrice}>
                    Sell my car ASAP
                </button>
            </div>


 
   </div>
   </div>
</section>

);
};